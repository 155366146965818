import { Calendar, Play, Pause, FastForward, Rewind } from 'lucide-react';
import useWebSocketDataStore from '../Store/Historical_Data_Store';
import { useEffect } from 'react';
import _ from 'lodash';

const DateSelector = ({ onDateChange }) => {
  const DEFAULT_DATE = '2024-01-02';

  const {
    sendHistoricalDataRequest,
    isPlaying,
    replaySpeed,
    setIsPlaying,
    setReplaySpeed,
    resetReplay,
    selectedDate,
    setSelectedDate
  } = useWebSocketDataStore();

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(DEFAULT_DATE);
      if (sendHistoricalDataRequest) {
        sendHistoricalDataRequest(DEFAULT_DATE);
      }
    }
  }, [sendHistoricalDataRequest, selectedDate, setSelectedDate]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
    resetReplay();

    if (sendHistoricalDataRequest) {
      sendHistoricalDataRequest(newDate);
    }

    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  const handleSpeedChange = (change) => {
    setReplaySpeed((prev) => {
      const newSpeed = prev + change;
      // Limit between 10ms (fastest) and 2000ms (slowest)
      return Math.max(25, Math.min(3000, newSpeed));
    });
  };

  const handleSpeedInput = (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      // Limit between 10ms (fastest) and 2000ms (slowest)
      setReplaySpeed(Math.max(25, Math.min(3000, value)));
    }
  };


  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: '2rem',
        marginTop: '1rem',
        marginBottom: '1rem',
      }}
    >
      {/* Date Selector */}
      <div style={{ display: 'inline-flex', alignItems: 'center', gap: '0.5rem' }}>
        <span style={{ color: 'white', fontSize: '1rem' }}>Date</span>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: '#1f2937',
            padding: '0.5rem 0.75rem',
            borderRadius: '0.375rem',
            border: '1px solid #374151',
          }}
        >
          <input
            type="date"
            value={selectedDate || DEFAULT_DATE}
            onChange={handleDateChange}
            style={{
              backgroundColor: 'transparent',
              color: 'white',
              outline: 'none',
              border: 'none',
              fontSize: '0.875rem',
            }}
            max={new Date().toISOString().split('T')[0]}
          />
        </div>
      </div>

      {/* Speed Selector */}
      <div style={{ display: 'inline-flex', alignItems: 'center', gap: '0.5rem' }}>
        <span style={{ color: 'white', fontSize: '1rem' }}>Speed</span>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: '#1f2937',
            padding: '0.5rem 0.75rem',
            borderRadius: '0.375rem',
            border: '1px solid #374151',
          }}
        >
          <input
            type="number"
            value={replaySpeed}
            onChange={handleSpeedInput}
            style={{
              width: '4rem',
              textAlign: 'center',
              backgroundColor: 'transparent',
              color: 'white',
              outline: 'none',
              border: 'none',
              fontSize: '0.875rem',
            }}
            min="25"
            max="2000"
            step="25"
          />
          <span style={{ color: 'white', fontSize: '0.875rem', marginLeft: '0.25rem' }}>ms</span>
        </div>
      </div>

      {/* Play/Pause Button */}
      <button
        onClick={() => setIsPlaying(!isPlaying)}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0.5rem 1rem',
          borderRadius: '0.375rem',
          backgroundColor: '#1f2937',
          border: '1px solid #374151',
          color: 'white',
          cursor: 'pointer',
        }}
      >
        {isPlaying ? <Pause size={16} /> : <Play size={16} />}
      </button>
    </div>
  );
};

export default DateSelector;





