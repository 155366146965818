import React, { useEffect, useRef, useState } from 'react';
import DateSelector from './Historical_Data_Selector';
import CustomDataFeed from './CustomDataFeed';
import useWebSocketDataStore from '../Store/Historical_Data_Store';
import { RotateCcw } from 'lucide-react';

//Indicator Imports
import { totalGEXGetter } from './Total_Gex';
import { totalDeltaGetter } from './Total_Delta';
import { totalThetaGetter } from './Total_Charm';
import { totalVegaGetter } from './Total_Vanna';
import { gexTrendStrengthGetter } from './Gex_Trend_Strength';
import { gexPairGetter } from './SPX_GEX_Pair';
import { deltaPairGetter } from './SPX_Delta_Pair';
import { thetaPairGetter } from './SPX_Theta_Pair';
import { vegaPairGetter } from './SPX_Vega_Pair';
import { gammaLevelsGetter } from './Gamma_Levels';
import { dynamicLevelsGetter } from './Dynamic_Levels';


const HistoricalFlowsDashboard = () => {
  const chartContainerRef = useRef(null);
  const tvWidgetRef = useRef(null);
  const replayIntervalRef = useRef(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isReplaying, setIsReplaying] = useState(false);
  const [currentDataIndex, setCurrentDataIndex] = useState(0);
  const isMobile = window.innerWidth <= 768;
  const [isLoading, setIsLoading] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState("");
  const [activeIndicators, setActiveIndicators] = useState([]);
    // Determine if the device is mobile

    // This is your mapping for initial load
  const indexIndicators = {
    'SPX': {
      defaultIndicators: ['SPX Dynamic Levels', 'SPX GEX Levels', 'SPX Total GEX', 'SPX GEX Pair', 'SPX GEX Trend Strength'],
      prefix: 'SPX_'
    },
  };

  const loadDefaultIndicators = async (chart, symbol) => {
  const config = indexIndicators[symbol];
  if (!config || !config.defaultIndicators) {
    console.warn(`No default indicators configured for symbol: ${symbol}`);
    return;
  }

  // Remove existing studies first
  try {
    const studies = chart.getAllStudies();
    for (const study of studies) {
      await chart.removeEntity(study.id);
    }
  } catch (err) {
    console.error('Error removing existing studies:', err);
  }

  // Add small delay before creating new indicators
  await new Promise(resolve => setTimeout(resolve, 100));

  // Load new indicators sequentially
  for (const indicator of config.defaultIndicators) {
    try {
      await chart.createStudy(indicator, false, false);
      // Add small delay between each indicator creation
      await new Promise(resolve => setTimeout(resolve, 50));
    } catch (err) {
      console.warn(`Failed to create study ${indicator}:`, err);
    }
  }
};

  // Effect to check for initial data
  useEffect(() => {
    const checkForData = () => {
      const historicalData = useWebSocketDataStore.getState().historicalGreekData;
      if (historicalData && historicalData.length > 0) {
        setIsDataReady(true);
      } else {
        setTimeout(checkForData, 100);
      }
    };
    checkForData();
  }, []);

  // Initialize TradingView widget
  useEffect(() => {
    if (!isDataReady || !window.TradingView || tvWidgetRef.current) {
      return;
    }

    const historicalData = useWebSocketDataStore.getState().historicalGreekData;

    const widgetOptions = {
            symbol: 'SPX',
            interval: '1',
            container: chartContainerRef.current,
            library_path: '/charting_library/',
            datafeed: new CustomDataFeed({
              initialData: historicalData,
              isReplaying,
              currentDataIndex,
            }),
            autosize: true,
            theme: 'Dark',
            timezone: 'America/New_York',
            debug: false,
            timeframe: '1',
            overrides: {
              "paneProperties.backgroundGradientStartColor": "#000000",
              "paneProperties.backgroundGradientEndColor": "#131722",
              'paneProperties.vertGridProperties.color': 'rgba(173,173,173,0.06)',
              'paneProperties.horzGridProperties.color': 'rgba(173,173,173,0.06)',
              'scalesProperties.textColor': '#FFFFFF',
              'chartProperties.paneProperties.borderColor': '#000000',
              // 'timeScale.shiftVisibleRangeOnNewBar': false,

            },
            disabled_features:  [
              'create_volume_indicator_by_default',
              'use_real_time_subscription',
              'adaptive_logo',
              'border_around_the_chart',
              'volume_force_overlay',
              'display_market_status',
              'shift_visible_range_on_new_bar',
              // 'scroll_past_end',
              'use_real_time_subscription',
                ],
            enabled_features: [
              'fix_left_edge',
              'fix_right_edge',
              'scroll_past_end',
              'use_localstorage_for_settings',
              'save_chart_properties_to_local_storage',
            ],
            time_frames: [{ text: "1D", resolution: "1", description: "1 Day" }],
            timeScale: {
              timeVisible: true,
              secondsVisible: false,
            },
      custom_indicators_getter: async (PineJS) => {
        try {
          const dynamicIndicators = await dynamicLevelsGetter(PineJS);
          const gammaIndicators = await gammaLevelsGetter(PineJS);
          const gexIndicators = await totalGEXGetter(PineJS);
          const netDeltaIndicator = await totalDeltaGetter(PineJS);
          const netThetaIndicator = await totalThetaGetter(PineJS);
          const netVegaIndicator = await totalVegaGetter(PineJS);
          const gexPairIndicator = await gexPairGetter(PineJS);
          const deltaPairIndicator = await deltaPairGetter(PineJS);
          const thetaPairIndicator = await thetaPairGetter(PineJS);
          const vegaPairIndicator = await vegaPairGetter(PineJS);
          const gexTrendStrengthIndicator = await gexTrendStrengthGetter(PineJS);

          return [
            ...dynamicIndicators,
            ...gammaIndicators,
            ...gexIndicators,
            ...netDeltaIndicator,
            ...netThetaIndicator,
            ...netVegaIndicator,
            ...gexPairIndicator,
            ...deltaPairIndicator,
            ...thetaPairIndicator,
            ...vegaPairIndicator,
            ...gexTrendStrengthIndicator
          ];
        } catch (error) {
          console.error('Error loading custom indicators:', error);
          return [];
        }
      }
    };

    tvWidgetRef.current = new window.TradingView.widget(widgetOptions);
    tvWidgetRef.current.onChartReady(() => {
      window.tvWidget = tvWidgetRef.current;

      tvWidgetRef.current.setCSSCustomProperty('--tv-color-pane-background', '#000000');
      tvWidgetRef.current.setCSSCustomProperty('--tv-color-toolbar-button-text', '#FFFFFF');

      setTimeout(async () => {
        const chart = window.tvWidget.activeChart();

        // First load the indicators
        await loadDefaultIndicators(chart, 'SPX');

        // Set up study tracking
        let previousStudies = chart.getAllStudies().map(study => study.name);
        // console.log('Initial studies:', previousStudies);
        setActiveIndicators(previousStudies);

        // Handle symbol changes
        chart.onSymbolChanged().subscribe(null, () => {
          const currentSymbol = chart.symbol();
          loadDefaultIndicators(chart, currentSymbol);
        });

        // Check for changes every second
        const studyTracker = setInterval(() => {
          const currentStudies = chart.getAllStudies().map(study => study.name);

          // Compare with previous state
          if (JSON.stringify(currentStudies) !== JSON.stringify(previousStudies)) {
            // console.log('Studies changed:', {
            //   previous: previousStudies,
            //   current: currentStudies,
            //   added: currentStudies.filter(s => !previousStudies.includes(s)),
            //   removed: previousStudies.filter(s => !currentStudies.includes(s))
            // });
            previousStudies = currentStudies;
            setActiveIndicators(currentStudies);
          }
        }, 500);

        // Clean up interval when component unmounts
        return () => clearInterval(studyTracker);
      }, 100);
    });

  }, [isDataReady, isReplaying, currentDataIndex]);

    const handleDateSelect = (newDate, forceReset = false) => {
      if (!forceReset) {
        setIsLoading(true);
        setNoDataMessage("");
      }

      if (tvWidgetRef.current) {
        // console.log("Removing existing TradingView widget...");
        tvWidgetRef.current.remove();
        tvWidgetRef.current = null;
      }

      const initializeWidget = () => {
        const historicalData = useWebSocketDataStore.getState().historicalGreekData;

        if (!forceReset && (!historicalData || historicalData.length === 0)) {
          setIsLoading(false);
          setNoDataMessage("Please select another trading day.");
          return;
        }

          tvWidgetRef.current = new window.TradingView.widget({
            symbol: 'SPX',
            interval: '1',
            container: chartContainerRef.current,
            library_path: '/charting_library/',
            datafeed: new CustomDataFeed({
              initialData: historicalData,
              isReplaying,
              currentDataIndex,
            }),
            autosize: true,
            theme: 'Dark',
            timezone: 'America/New_York',
            debug: false,
            timeframe: '1',
            overrides: {
              "paneProperties.backgroundGradientStartColor": "#000000",
              "paneProperties.backgroundGradientEndColor": "#131722",
              'paneProperties.vertGridProperties.color': 'rgba(173,173,173,0.06)',
              'paneProperties.horzGridProperties.color': 'rgba(173,173,173,0.06)',
              'scalesProperties.textColor': '#FFFFFF',
              'chartProperties.paneProperties.borderColor': '#000000',
              // 'timeScale.shiftVisibleRangeOnNewBar': false,

            },
            disabled_features:  [
              'create_volume_indicator_by_default',
              'use_real_time_subscription',
              'adaptive_logo',
              'border_around_the_chart',
              'volume_force_overlay',
              'display_market_status',
              'shift_visible_range_on_new_bar',
              // 'scroll_past_end',
              'use_real_time_subscription',
                ],
            enabled_features: [
              'fix_left_edge',
              'fix_right_edge',
              'scroll_past_end',
              'use_localstorage_for_settings',
              'save_chart_properties_to_local_storage',
            ],
            time_frames: [{ text: "1D", resolution: "1", description: "1 Day" }],
            timeScale: {
              timeVisible: true,
              secondsVisible: false,
            },
            custom_indicators_getter: async (PineJS) => {
                try {
                    const dynamicIndicators = await dynamicLevelsGetter(PineJS);
                    const gammaIndicators = await gammaLevelsGetter(PineJS);
                    const gexIndicators = await totalGEXGetter(PineJS);
                    const netDeltaIndicator = await totalDeltaGetter(PineJS);
                    const netThetaIndicator = await totalThetaGetter(PineJS);
                    const netVegaIndicator = await totalVegaGetter(PineJS);
                    const gexPairIndicator = await gexPairGetter(PineJS);
                    const deltaPairIndicator = await deltaPairGetter(PineJS);
                    const thetaPairIndicator = await thetaPairGetter(PineJS);
                    const vegaPairIndicator = await vegaPairGetter(PineJS);
                    const gexTrendStrengthIndicator = await gexTrendStrengthGetter(PineJS);

                    return [
                        ...dynamicIndicators,
                        ...gammaIndicators,
                        ...gexIndicators,
                        ...netDeltaIndicator,
                        ...netThetaIndicator,
                        ...netVegaIndicator,
                        ...gexPairIndicator,
                        ...deltaPairIndicator,
                        ...thetaPairIndicator,
                        ...vegaPairIndicator,
                        ...gexTrendStrengthIndicator
                    ];
                } catch (error) {
                    console.error('Error loading custom indicators:', error);
                    return [];
                }
            }
          });

          tvWidgetRef.current.onChartReady(() => {
            window.tvWidget = tvWidgetRef.current;

            tvWidgetRef.current.setCSSCustomProperty('--tv-color-pane-background', '#070707');
            tvWidgetRef.current.setCSSCustomProperty('--tv-color-toolbar-button-text', '#FFFFFF');

            setTimeout(async () => {
              try {
                const chart = window.tvWidget.activeChart();

                // 1) If there are zero indicators, load defaults immediately
                if (!activeIndicators || activeIndicators.length === 0) {
                  console.log('No indicators found in state. Loading default indicators...');
                  await loadDefaultIndicators(chart, 'SPX'); // or use chart.symbol() if needed
                } else {
                  // 2) If we do have some indicators in state, restore them
                  // console.log('Restoring indicators from state:', activeIndicators);
                  for (const indicator of activeIndicators) {
                    try {
                      await chart.createStudy(indicator, false, false);
                      await new Promise(resolve => setTimeout(resolve, 50));
                    } catch (err) {
                      console.warn(`Failed to restore study ${indicator}:`, err);
                    }
                  }
                }

                // 3) Set up indicator tracking to keep `activeIndicators` updated
                let previousStudies = chart.getAllStudies().map(study => study.name);
                // console.log('Current studies:', previousStudies);
                setActiveIndicators(previousStudies);

                const studyTracker = setInterval(() => {
                  const currentStudies = chart.getAllStudies().map(study => study.name);
                  if (JSON.stringify(currentStudies) !== JSON.stringify(previousStudies)) {
                    previousStudies = currentStudies;
                    setActiveIndicators(currentStudies);
                  }
                }, 1000);

                // 4) On symbol change, either restore from state or load defaults
                chart.onSymbolChanged().subscribe(null, () => {
                  const currentSymbol = chart.symbol();

                  // If we still have indicators, restore them on the new symbol
                  if (activeIndicators && activeIndicators.length > 0) {
                    for (const indicator of activeIndicators) {
                      chart.createStudy(indicator, false, false);
                    }
                  } else {
                    // Otherwise, load defaults for this symbol
                    loadDefaultIndicators(chart, currentSymbol);
                  }
                });

              } catch (err) {
                console.error('Error setting up chart:', err);
              }
            }, 500);
          });

          if (!forceReset) {
            setIsLoading(false);
          }
        };

        if (forceReset) {
          initializeWidget();
        } else {
          let attempts = 0;
          const maxAttempts = 100;

          const waitForDataAndReinitialize = () => {
            const historicalData = useWebSocketDataStore.getState().historicalGreekData;
            attempts++;

            if (historicalData.length > 0) {
              initializeWidget();
            } else if (attempts < maxAttempts) {
              setTimeout(waitForDataAndReinitialize, 100);
            } else {
              setIsLoading(false);
              setNoDataMessage("Please select a different trading day.");
            }
          };

          waitForDataAndReinitialize();
        }
      };


  const handleReplay = () => {
      // console.log("handleReplay called");
      if (!isReplaying) {
        setIsReplaying(true);
        const historicalData = useWebSocketDataStore.getState().historicalGreekData;
        // console.log("Historical data length:", historicalData.length);

        let currentIndex = 0;

        if (replayIntervalRef.current) {
          // console.log("Clearing existing interval");
          clearInterval(replayIntervalRef.current);
        }

        replayIntervalRef.current = setInterval(() => {
          // console.log("Interval tick, current index:", currentIndex);
          if (currentIndex >= historicalData.length) {
            // console.log("Replay finished");
            clearInterval(replayIntervalRef.current);
            replayIntervalRef.current = null;
            setIsReplaying(false);
            return;
          }

          const chart = tvWidgetRef.current?.chart();
          if (chart && chart.datafeed) {
            // console.log("Updating data for index:", currentIndex);
            chart.datafeed.updateData(historicalData[currentIndex]);
            chart.datafeed.currentReplayIndex = currentIndex;
          } else {
            // console.log("Chart or datafeed not available");
          }

          currentIndex++;
          setCurrentDataIndex(currentIndex);
        }, 1000);
      } else {
        // console.log("Already replaying - skipping");
      }
    };

  useEffect(() => {
    return () => {
      if (replayIntervalRef.current) {
        clearInterval(replayIntervalRef.current);
      }
      if (tvWidgetRef.current) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = null;
      }
    };
  }, []);

  return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100vh',
          backgroundColor: '#070707',
          position: 'relative'  // Add this to contain absolute children
        }}
      >
        <div
          className="flex flex-row items-center gap-4 p-4"
          style={{
            marginTop: isMobile ? '.5rem' : '1rem',
            marginBottom: isMobile ? '.5rem' : '1rem'
          }}
        >
          <DateSelector onDateChange={handleDateSelect} />

          <button
            onClick={() => {
              console.log("[Replay] Reset button clicked");

              // Clear any ongoing replay
              if (replayIntervalRef.current) {
                clearInterval(replayIntervalRef.current);
                replayIntervalRef.current = null;
              }

              // Reset component state
              setIsReplaying(false);
              setCurrentDataIndex(0);

              // Reset store state
              useWebSocketDataStore.getState().resetReplay();

              // Reset the widget and its datafeed
              if (tvWidgetRef.current) {
                const datafeed = tvWidgetRef.current._options.datafeed;
                datafeed.resetReplay(); // Using our new method
                tvWidgetRef.current.remove();
                tvWidgetRef.current = null;
              }

              // Reinitialize the widget with existing data
              handleDateSelect(null, true);
            }}
            disabled={isReplaying}
            className={`flex items-center px-4 py-2 rounded-md ${
              isReplaying
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-gray-800 text-white hover:bg-gray-700'
            }`}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0.5rem 2rem',
              borderRadius: '0.375rem',
              backgroundColor: '#1f2937',
              border: '1px solid #374151',
              color: 'white',
              marginLeft: '1.75rem',
              transform: 'translateY(3.5px)',
            }}
          >
            <RotateCcw size={16} className="mr-2" />
            <span>Restart</span>
          </button>
        </div>

        {/* Loading Screen - Position absolute with proper overlay */}
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              top: '64px',  // Height of the header
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#070707',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 50
            }}
          >
            <img
              src="/logo192.png"
              alt="SPX Gamma"
              className="w-24 h-24 mb-8"
              style={{ filter: 'brightness(0.95)' }}
            />
            <div className="flex flex-col items-center space-y-3">
              <h3
                className="text-2xl font-semibold"
                style={{ color: '#B18800', fontSize: '36px' }}
              >
                Retrieving Data...
              </h3>
              <p
                className="text-base"
                style={{ color: '#B18800', opacity: 0.8, fontSize: '24px' }}
              >
                Please wait while we load the data for the selected date.
              </p>
            </div>
          </div>
        )}

        {/* No Data Message */}
        {!isLoading && noDataMessage && (
          <div
            style={{
              position: 'absolute',
              top: '64px',  // Height of the header
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#070707',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 50
            }}
          >
            <img
              src="/logo192.png"
              alt="SPX Gamma"
              className="w-24 h-24 mb-8"
              style={{ filter: 'brightness(0.95)' }}
            />
            <div className="flex flex-col items-center space-y-3">
              <h3
                className="text-2xl font-semibold"
                style={{ color: '#FF0000', fontSize: '36px' }}
              >
                No Data Found
              </h3>
              <p
                className="text-base"
                style={{ color: '#FF0000', opacity: 0.8, fontSize: '24px' }}
              >
                 Please select another date.
              </p>
            </div>
          </div>
        )}

        {/* Chart */}
        <div
          ref={chartContainerRef}
          style={{
            flex: 1,
            border: '1px solid #333',
            borderRadius: '8px',
          }}
        />
      </div>
    );

};

export default HistoricalFlowsDashboard;































