import useWebSocketDataStore from "../Store/Historical_Data_Store.js";
import { processHistoricalData } from './HistoricalDataAnalyzer';
import { useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';

const HistoricalClient = () => {
 const {
   setSendHistoricalDataRequest,
   selectedDate
 } = useWebSocketDataStore();

 const socketRef = useRef(null);
 const sendRequestRef = useRef(null);
 const initialDateRef = useRef(null);  // Add this to track initial date

 // Create a debounced version of the send request function
  const debouncedSendRequest = useCallback(
      _.debounce((date) => {
        console.log('[Debounced] Sending request for date:', date);
        if (socketRef.current?.readyState === WebSocket.OPEN) {
          sendRequestRef.current?.(date);
        } else {
          initialDateRef.current = date;
        }
      }, 2000, {
        leading: false,  // Don't execute on the first call
        trailing: true,  // Execute on the last call after the delay
        maxWait: 5000   // Maximum time to wait before executing
      }),
      []
  );

 useEffect(() => {
   console.log('Setting up WebSocket...');
   let isComponentMounted = true;

   const connectWebSocket = () => {
     try {
       const wsURL = 'wss://react-historical-websocket-pro-b38bf068da0e.herokuapp.com';
       // console.log('Attempting to connect to:', wsURL);
       socketRef.current = new WebSocket(wsURL);

       socketRef.current.addEventListener('open', () => {
         // console.log('WebSocket connected!');
         if (!isComponentMounted) return;

         const sendRequest = (date) => {
           // console.log('Attempting to send request for date:', date);
           if (socketRef.current?.readyState === WebSocket.OPEN) {
             const request = {
               type: 'historical-data',
               date
             };
             socketRef.current.send(JSON.stringify(request));
             // console.log('Request sent:', request);
           } else {
             // console.log('Socket not ready. ReadyState:', socketRef.current?.readyState);
           }
         };

         sendRequestRef.current = sendRequest;
         setSendHistoricalDataRequest(() => sendRequest);
         // console.log('Send request function set');

         // Send initial date if it was queued
         if (initialDateRef.current) {
           // console.log('Sending queued initial date:', initialDateRef.current);
           sendRequest(initialDateRef.current);
           initialDateRef.current = null;  // Clear the queue
         }
       });

       // Rest of the event listeners remain the same
       socketRef.current.addEventListener('message', (event) => {
         // console.log('Received message:', event.data);
         if (!isComponentMounted) return;
         try {
           const message = JSON.parse(event.data);
           if (message.greekData && Array.isArray(message.greekData)) {
             processHistoricalData(message);
           }
         } catch (error) {
           console.error('Error processing message:', error);
         }
       });

       socketRef.current.addEventListener('error', (error) => {
         console.error('WebSocket error:', error);
       });

       socketRef.current.addEventListener('close', () => {
         console.log('WebSocket closed');
       });

     } catch (error) {
       console.error('Error in connectWebSocket:', error);
     }
   };

   connectWebSocket();

   return () => {
     console.log('Cleaning up WebSocket...');
     isComponentMounted = false;
     if (socketRef.current) {
       setSendHistoricalDataRequest(null);
       socketRef.current.close();
       socketRef.current = null;
     }
   };
 }, []);

 // Handle all date changes with debounced function
 useEffect(() => {
   if (!selectedDate) return;
   // console.log('[Date Change] Queuing request for:', selectedDate);
   debouncedSendRequest(selectedDate);

   // Cleanup on unmount
   return () => {
     debouncedSendRequest.cancel();
   };
 }, [selectedDate, debouncedSendRequest]);

 return null;
};

export default HistoricalClient;


// import useWebSocketDataStore from "../Store/Historical_Data_Store.js";
// import { processHistoricalData } from './HistoricalDataAnalyzer';
// import { useEffect, useRef } from 'react';
//
// const HistoricalClient = () => {
//   const {
//     setSendHistoricalDataRequest,
//     selectedDate
//   } = useWebSocketDataStore();
//
//   const socketRef = useRef(null);
//   const sendRequestRef = useRef(null);
//   const initialDateRef = useRef(null);  // Add this to track initial date
//
//   useEffect(() => {
//     console.log('Setting up WebSocket...');
//     let isComponentMounted = true;
//
//     const connectWebSocket = () => {
//       try {
//         const wsURL = 'wss://react-historical-websocket-pro-b38bf068da0e.herokuapp.com';
//         // console.log('Attempting to connect to:', wsURL);
//         socketRef.current = new WebSocket(wsURL);
//
//         socketRef.current.addEventListener('open', () => {
//           // console.log('WebSocket connected!');
//           if (!isComponentMounted) return;
//
//           const sendRequest = (date) => {
//             // console.log('Attempting to send request for date:', date);
//             if (socketRef.current?.readyState === WebSocket.OPEN) {
//               const request = {
//                 type: 'historical-data',
//                 date
//               };
//               socketRef.current.send(JSON.stringify(request));
//               // console.log('Request sent:', request);
//             } else {
//               // console.log('Socket not ready. ReadyState:', socketRef.current?.readyState);
//             }
//           };
//
//           sendRequestRef.current = sendRequest;
//           setSendHistoricalDataRequest(() => sendRequest);
//           // console.log('Send request function set');
//
//           // Send initial date if it was queued
//           if (initialDateRef.current) {
//             // console.log('Sending queued initial date:', initialDateRef.current);
//             sendRequest(initialDateRef.current);
//             initialDateRef.current = null;  // Clear the queue
//           }
//         });
//
//         // Rest of the event listeners remain the same
//         socketRef.current.addEventListener('message', (event) => {
//           // console.log('Received message:', event.data);
//           if (!isComponentMounted) return;
//           try {
//             const message = JSON.parse(event.data);
//             if (message.greekData && Array.isArray(message.greekData)) {
//               processHistoricalData(message);
//             }
//           } catch (error) {
//             console.error('Error processing message:', error);
//           }
//         });
//
//         socketRef.current.addEventListener('error', (error) => {
//           console.error('WebSocket error:', error);
//         });
//
//         socketRef.current.addEventListener('close', () => {
//           console.log('WebSocket closed');
//         });
//
//       } catch (error) {
//         console.error('Error in connectWebSocket:', error);
//       }
//     };
//
//     connectWebSocket();
//
//     return () => {
//       console.log('Cleaning up WebSocket...');
//       isComponentMounted = false;
//       if (socketRef.current) {
//         setSendHistoricalDataRequest(null);
//         socketRef.current.close();
//         socketRef.current = null;
//       }
//     };
//   }, []);
//
//   // Handle all date changes
//   useEffect(() => {
//     // console.log('Date change detected:', selectedDate);
//     if (!selectedDate) return;
//
//     if (socketRef.current?.readyState === WebSocket.OPEN) {
//       // Socket is ready, send immediately
//       // console.log('Sending request for date:', selectedDate);
//       sendRequestRef.current?.(selectedDate);
//     } else {
//       // Socket not ready, queue the initial date
//       // console.log('Queueing initial date:', selectedDate);
//       initialDateRef.current = selectedDate;
//     }
//   }, [selectedDate]);
//
//   return null;
// };
//
// export default HistoricalClient;


